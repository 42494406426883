import Badge from "antd/lib/badge";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";
import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectRow } from "../../../state/orders";
import { OrderStatus } from "../../../utils/constants";
import { formatCurrency, formatOrderRecipient } from "../../../utils/helpers";
import Thumbnail from "../Thumbnail";
import styles from "./css/orderRow.module.scss";

const { Text, Paragraph } = Typography;

const OrderRow = ({ loading, order, filter }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();
  const { selectedRowKeys } = useSelector((state) => state.orders);

  const renderTitle = () => (
    <Space>
      <Text strong>{order.date}</Text>
      <Text>
        {t(`orders.orderNo`)}: {order.orderNo}
      </Text>
      <Badge
        status={
          order.status === OrderStatus.ARRIVED_AT_WAREHOUSE
            ? "warning"
            : "success"
        }
        text={t(`orders.status.${order.status}`)}
      />
    </Space>
  );

  const dataSource = order.lineItems.items;

  const getRowKey = (record) => `${order.id}-${record.skuId}`;

  const isConsolidationAvailable =
    order.status === OrderStatus.ARRIVED_AT_WAREHOUSE;
  const isInConsolidationView = filter === OrderStatus.ARRIVED_AT_WAREHOUSE;

  return (
    <Table
      loading={loading}
      bordered
      scroll={{ x: 1000 }}
      pagination={false}
      dataSource={dataSource}
      title={renderTitle}
      showHeader={false}
      rowKey={getRowKey}
      className={classNames(styles.tableContainer, {
        [styles.highlighted]: isConsolidationAvailable,
      })}
      rowSelection={
        isInConsolidationView && {
          type: "checkbox",
          selectedRowKeys,
          onSelect: (record) => dispatch(selectRow(getRowKey(record))),
        }
      }
      columns={[
        {
          width: "100px",
          key: "thumbnail",
          render: (record) => <Thumbnail {...record} />,
        },
        {
          width: "auto",
          key: "description",
          render: (record) => (
            <>
              <Paragraph strong>{record.name}</Paragraph>
              <Text>{record.variants.join(" | ")}</Text>
            </>
          ),
        },
        {
          width: "15%",
          dataIndex: "unitPrice",
          render: (unitPrice, record) =>
            formatCurrency(unitPrice, record.currency, language),
        },
        {
          width: "10%",
          dataIndex: "quantity",
        },
        {
          width: "15%",
          key: "amount",
          render: (_, _row, index) => ({
            children: formatCurrency(order.amount, order.currency, language),
            props: { rowSpan: index ? 0 : dataSource.length },
          }),
        },
        {
          width: "10%",
          key: "destination",
          render: (_, _row, index) => {
            return {
              children: formatOrderRecipient(order),
              props: { rowSpan: index ? 0 : dataSource.length },
            };
          },
        },
        {
          width: "10%",
          key: "actions",
          render: (_, _row, index) => ({
            children: (
              <Link to={`/app/orders/${order.id}`}>
                {t(`orders.actions.viewDetails`)}
              </Link>
            ),
            props: { rowSpan: index ? 0 : dataSource.length },
          }),
        },
      ]}
    />
  );
};

export default OrderRow;
