import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Empty, Table as TableColumns } from "antd";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { LIST_ALL_ORDERS } from "../../../graphql/order";
import { setOrders } from "../../../state/orders";
import { OrderStatus } from "../../../utils/constants";
import { orderColumns } from "./columns";
import styles from "./css/orderTable.module.scss";
import OrderRow from "./OrderRow";

const OrderTable = ({ filter }) => {
  const { t } = useTranslation();
  const [fetchOrders, { data, loading }] = useLazyQuery(LIST_ALL_ORDERS, {
    variables: { sortDirection: "DESC" },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchOrders(
      filter ? { variables: { filter: { status: { eq: filter } } } } : undefined
    );
    console.log("Updated Fetch Orders.");
  }, [fetchOrders, filter]);

  const orders = data?.listOrders?.items;

  const dispatch = useDispatch();

  useEffect(() => {
    if (orders) dispatch(setOrders(orders));
  }, [dispatch, orders]);

  const submitConsolidation =
    orders && orders.length > 0 && filter === OrderStatus.ARRIVED_AT_WAREHOUSE;

  return (
    <>
      <TableColumns
        rowKey="id"
        pagination={false}
        className={styles.tableContainer}
        columns={orderColumns.map((column) => {
          const { width, title } = column;
          return { width, title: t(title) };
        })}
      />
      {orders?.length ? (
        orders.map((order) => (
          <OrderRow
            loading={loading}
            key={order.id}
            order={order}
            filter={filter}
          />
        ))
      ) : (
        <Empty />
      )}
      {submitConsolidation && (
        <Button onClick={() => navigate("/app/shipping/delivery")}>
          {t("common.submit")}
        </Button>
      )}
    </>
  );
};

export default OrderTable;
