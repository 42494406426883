export const consolidationOrderColumns = [
  {
    width: "100px",
  },
  {
    width: "25%",
    title: "consolidationOrders.parcel",
  },
  {
    width: "15%",
    title: "consolidationOrders.amount",
  },
  {
    width: "10%",
    title: "consolidationOrders.weight",
  },
  {
    width: "auto",
    title: "consolidationOrders.shippingMethod",
  },
  {
    width: "auto",
  },
];
export const orderColumns = [
  {
    width: "100px",
  },
  {
    width: "auto",
    title: "orders.description",
  },
  {
    width: "15%",
    title: "orders.unitPrice",
  },
  {
    width: "10%",
    title: "orders.quantity",
  },
  {
    width: "15%",
    title: "orders.value",
  },
  {
    width: "10%",
    title: "orders.warehouse",
  },
  {
    width: "10%",
  },
];
