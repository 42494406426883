import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import React from "react";
import { useTranslation } from "react-i18next";

import ResponsiveTitle from "../../components/ResponsiveTitle";
import OrderTable from "./list-orders/OrderTable";

const MwmOrders = () => {
  const { t } = useTranslation();
  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <ResponsiveTitle>{t("shipping.orders")}</ResponsiveTitle>
      <Row justify="center">
        <Col>
          <OrderTable />
        </Col>
      </Row>
    </Space>
  );
};

export default MwmOrders;
